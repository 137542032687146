import Vue from 'vue'
Vue.filter('dateFilter', function (val) {
    // 时间戳 
    let timestamp = val;
    // 此处时间戳以毫秒为单位
    let date = new Date(parseInt(timestamp) * 1000);
    let Year = date.getFullYear();
    let Moth = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
    let Day = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
    let Hour = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours());
    let Minute = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
    let Sechond = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
    let GMT = Year + '-' + Moth + '-' + Day + '   ' + Hour + ':' + Minute + ':' + Sechond;
    return GMT

})
 
//处理为级联数据
export const packagingSelector = (res) => {

    const { pId, data, pid, label, id } = res
    // 创建一个内部函数selector
    const selector = (pId, arr) => {
      const res = []
      arr.forEach(i => {
        // 如果某对象的pid等于传进来的数 这里第一次传进来的pId = 0也就是查找最顶级
        if (i[pid] === pId) {
          // 自己调用自己并传入当前位置的对象的id和整个数组对象
          // 由于查出来的是子级 所以赋值给children
          const children = selector(i[id], data)
          // 原来的数据不符合element要求的格式，所以这里进行重构
          const obj = {
            label: i[label],
            value: i[id]
          }
          // 如果有 children 则插入 obj 中
          if (children.length) {
            obj.children = children
          }
          res.push(obj)
        }
      })
      return res
    }
    // 调用并返回函数,传入pId和数组并赋值给selector
    return selector(pId, data)
  }