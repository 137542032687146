<template>
  <div class="app">
    <!-- 一级出口 -->
    <!-- <keep-alive exclude="v-">
      <router-view></router-view>
    </keep-alive> -->
    <router-view></router-view>
    <audio src="" id="eventAudio"></audio>
  </div>
</template>

<script>
import { websocket } from "@/utils/index";

export default {
  created() {
    websocket();
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.app {
  height: 100%;
}
</style>