import http from './http'
// 登录接口
export const api_users_checkLogin = (data) => {
    // 调用axios
    return http({
        url: '/admin-service/login',
        method: 'post',
        data,
    })
}

// 管理员列表 
export const api_users_list = (params) => {
    // 调用axios
    return http({
        url: '/admin-service/adminlist',
        method: 'get',
        params
    })
}

// 添加管理员 
export const api_users_add = (data) => {
    // 调用axios
    return http({
        url: '/admin-service/adminadd',
        method: 'post',
        data,
    })
}

// 管理员编辑 
export const api_users_edit = (data) => {
    // 调用axios
    return http({
        url: '/admin-service/admin/edit',
        method: 'post',
        data,
    })
}

// 删除管理员 
export const api_users_del = (data) => {
    // 调用axios
    return http({
        url: '/admin-service/admin/delete',
        method: 'post',
        data,
    })
}

// 重置密码
export const api_users_editpwd = (data) => {
    return http({
        url: '/admin-service/admin/resetting',
        method: 'post',
        data
    })
}

// 登录日志
export const api_log = (params) => {
    // 调用axios
    return http({
        url: '/admin-service/login/log',
        method: 'get',
        params
    })
}

// 角色列表
export const api_role_list = (params) => {
    // 调用axios
    return http({
        url: '/admin-service/auth/role',
        method: 'get',
        params
    })
}

// 创建角色 
export const api_role_add = (data) => {
    return http({
        url: '/admin-service/auth/role',
        method: 'post',
        data
    })
}


// 编辑角色
export const api_role_edit = ({ id }, params) => {
    return http({
        url: `/admin-service/auth/role/${id}`,
        method: 'patch',
        params
    })
}
// 角色详情
export const api_role_detail = ({ id }) => {
    return http({
        url: `/admin-service/auth/role/${id}`,
        method: 'get',
    })
}

// 删除角色
export const api_role_del = ({ id }) => {
    return http({
        url: `/admin-service/auth/role/${id}`,
        method: 'delete',
    })
}

// 角色权限列表
export const api_permissionList = (params) => {
    return http({
        url: `/admin-service/auth/permission`,
        method: 'get',
        params
    })
}

// 绑定权限
export const api_role_permission = ({ role_id }, params) => {
    return http({
        url: `/admin-service/auth/permission/${role_id}`,
        method: 'patch',
        params
    })
}