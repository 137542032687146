import Vue from "vue";

export const websocket = () => {

    var ws = new WebSocket("wss://assignment-api.huabanshou.cn:2346");
    let tk = localStorage.getItem('tk') || '';
    if (tk) {
        ws.onopen = function (e) {
            //console.log("打开连接成功");
        };

        ws.onmessage = function (e) {
            let data = JSON.parse(e.data);
            let sendData = loginData(tk);
            if (data.code != 200) {
                console.log("出错了", data);
                return;
            }
            data = data.data;
            switch (data.type) {
                case "onConnect":
                    console.log("建立连接");
                    ws.send(JSONToStr(sendData));
                    return;
                    break;
                case "bindUid":
                    console.log("绑定成功");
                    return;
                    break;
                case "waitAdminMerchant":
                    console.log("待审核商户信息");

                    Vue.prototype.playAudio();
                    return;
                    break;
                case "waitAdminAccount":
                    console.log("待审核抖音信息");

                    Vue.prototype.playAudio();
                    return;
                    break;
            }
            console.log(data);
        };

        ws.onclose = function (e) {
            console.log("服务已断开");
        };
        ws.onerror = function (e) {
            console.log("连接出错");
            alert('连接出错，请重新连接')
        }
    }

}
export const JSONToStr = (data) => {
    return JSON.stringify(data);
}

export const loginData = (token) => {
    return { token, login_type: 'backstage' }
}
