<template>
  <div class="box1">
    <div class="login">
      <div class="bgImg">
        <div class="nihao">你好!</div>
        <div class="welcome">欢迎来到二当家后台管理系统</div>
        <img
          class="img1"
          src="http://store-tiktok.oss-cn-beijing.aliyuncs.com/mini/images/1680140946360509.png"
          alt=""
        />
      </div>
      <div class="loginForm">
        <div class="loginCenter">
          <div class="loginimg">
            <img
              class="img2"
              src="http://store-tiktok.oss-cn-beijing.aliyuncs.com/mini/images/1680140741992149.png"
              alt=""
            />
            <div class="erDang">二当家</div>
          </div>
          <el-form :model="form" label-position="top">
            <el-form-item>
              <div>登录账号</div>
              <el-input
                v-model="form.account"
                placeholder="请输入登录账号"
                @keydown.enter.native="handleEnterKey"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <div>密码</div>
              <el-input
                show-password
                v-model="form.password"
                placeholder="请输入登录密码"
                @keydown.enter.native="handleEnterKey"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button @click="Logins">登录</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div class="fllings">
      <div>
        <span>©</span>
        <span>2024</span>
        <span>四川银弓科技</span>
      </div>
      <div>
        <img
          class="guoImg"
          src="https://huabanshoutiktok.oss-cn-hangzhou.aliyuncs.com/mini/images/1707295725934842.png"
          alt=""
        />
        <img
          class="guoImg"
          src="https://huabanshoutiktok.oss-cn-hangzhou.aliyuncs.com/mini/images/1707295791114957.png"
          alt=""
        />
      </div>
      <div class="icp-i" @click="toICp">
        <span>备案号：</span>
        <span>蜀ICP备2022006999号-2</span>
      </div>
    </div>
  </div>
</template>

<script>
import { api_users_checkLogin, api_permissionList } from "@/api/users";
import { productiveRoutes } from "@/router";
export default {
  data() {
    return {
      form: {
        account: "",
        password: "",
      },
    };
  },
  mounted() {
    console.log("chjbjdkcd");
  },
  methods: {
    toICp() {
      window.open("https://beian.miit.gov.cn", "_blank");
    },
    handleEnterKey(e) {
      if (!this.form.account) return this.$message.error("请输入账号");
      if (!this.form.password) return this.$message.error("请输入密码");
      this.Logins();
    },
    Logins() {
      api_users_checkLogin({
        ...this.form,
      }).then((res) => {
        if (res.data.code == 200) {
          if (res.data.msg) {
            this.$message.error(res.data.msg);
          } else {
            this.$notify.success("登录成功");
            localStorage.setItem("tk", res.data.data.scope);
            localStorage.setItem("userName", res.data.data.user_name);
            localStorage.setItem("id", res.data.data.user_id);
            // this.getPermission();

            setTimeout(() => {
              this.$router.push("/home");
            }, 500);
          }
        }
        if (res.data.code == 204) {
          this.$message.error(res.data.msg);
        }
      });
    },
    getPermission() {
      api_permissionList().then((res) => {
        let perArr = res.data.data.map((v) => v.name);
        localStorage.setItem("permissionsArr", perArr);

        // productiveRoutes();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.box1 {
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #ff616d 0%, #ff806d 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  .login {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
    left: 100px;
    .bgImg {
      width: 700px;
      height: 650px;
      position: absolute;
      right: 380px;
      z-index: 1;
      .nihao {
        font-size: 32px;
        color: #ff616d;
        position: absolute;
        top: 70px;
        left: 60px;
      }
      .welcome {
        position: absolute;
        top: 120px;
        left: 60px;
        color: #ff616d;
        font-size: 18px;
      }
      .img1 {
        width: 700px;
        height: 650px;
      }
    }
    .loginForm {
      width: 800px;
      height: 450px;
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
      background: #ffffff;
      border-radius: 30px 30px 30px 30px;
      position: relative;
      .loginCenter {
        width: 300px;
        height: 400px;
        // background: red;
        position: absolute;
        right: 40px;
        top: 20px;
        .loginimg {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 30px;
          .img2 {
            width: 60px;
            height: 60px;
            border-radius: 16px 16px 16px 16px;

            margin-right: 20px;
          }
          .erDang {
            color: #ff616d;
            font-size: 20px;
            font-weight: bold;
          }
        }
        .el-form {
          margin-top: 30px;
          .el-form-item {
            margin-bottom: 0;
          }
          .el-button {
            width: 300px;
            height: 44px;
            background: linear-gradient(43deg, #ff616d 0%, #ff806d 100%);
            border-radius: 25px 25px 25px 25px;
            margin-top: 30px;
            color: #ffffff;
          }
        }
      }
    }
  }
  .fllings {
    font-size: 17px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // text-align: center;
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    margin: auto;
    color: #fff;
    .guoImg {
      width: 26px;
      height: 26px;
      margin-left: 8px;
    }
    .icp-i {
      margin-left: 4px;
    }
    .icp-i:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
</style>
