

import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "@/views/Login"
import Layout from "@/views/Layout"
Vue.use(VueRouter)

const originalReplace = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalReplace.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    component: Login
  },
  // {
  //   path: '/reset',
  //   component: () => import('@/views/login/reset.vue')
  // },
  // 首页
  {
    path: '/home',
    meta: { title: '首页' },
    name: 'home',
    component: Layout,
    children: [
      {
        path: '/home',
        component: () => import('@/views/Layout/home')
      },
    ]
  },
  // 达人管理
  {
    path: '/master',
    redirect: '/master/list',
    meta: { title: '达人管理' },
    name: 'master',
    component: Layout,
    children: [
      {
        path: '/master/list',
        meta: { title: '达人列表', keepAlive: true },
        button: [{ title: '指派', name: 'assign' }, { title: '冻结', name: 'freeze' }, { title: '查看详情', name: 'detail' }, { title: '一键开启分销', name: 'setDistributor' }, { title: '恢复分销设置', name: 'resetDistributor' },],
        // name: 'masterList',
        component: { render(c) { return c('router-view') } },
        redirect: 'masterList',
        children: [
          {
            path: '/master/list',
            name: "masterList",
            component: () => import('@/views/Layout/master/list.vue'),
            meta: {
              keepAlive: true
            }
          },
          {
            path: '/master/list/info',
            meta: { title: '达人信息', keepAlive: true },
            name: 'masterInfo',
            component: () => import('@/views/Layout/master/info.vue')
          },
        ]
      },


      {
        path: '/master/verify',
        meta: { title: '达人审核' },
        button: [{ title: '导出', name: 'export' }, { title: '通过', name: 'pass' }, { title: '驳回', name: 'fail' }, { title: '更改达人等级', name: 'modifyLevel' },],
        name: 'masterVerify',
        component: () => import('@/views/Layout/master/verify.vue')
      },
      {
        path: '/master/tiktok',
        meta: { title: '抖音信息' },
        button: [{ title: '指派', name: 'assign' }, { title: '导出', name: 'export' },],
        name: 'masterTiktok',
        component: () => import('@/views/Layout/master/tiktok.vue')
      },
    ]
  },
  //服务商管理
  {
    path: '/provider',
    redirect: '/provider/list',
    meta: { title: '服务商管理' },
    name: 'provider',
    component: Layout,
    children: [
      {
        path: '/provider/list',
        meta: { title: '服务商列表' },
        button: [{ title: '指派', name: 'assign' }, { title: '查看详情', name: 'detail' },],
        name: 'providerList',
        component: { render(c) { return c('router-view') } },
        redirect: '/provider/list',
        // component: () => import('@/views/Layout/provider/list.vue')
        children: [
          {
            path: '/provider/list',
            name: "masterList",
            component: () => import('@/views/Layout/provider/list.vue'),
            meta: {
              keepAlive: true
            }
          },
          {
            path: '/provider/list/info',
            meta: { title: '服务商信息' },
            name: 'providerInfo',
            component: () => import('@/views/Layout/provider/info.vue')
          },

        ]
      },


      {
        path: '/provider/verify',
        meta: { title: '服务商审核' },
        button: [{ title: '导出', name: 'export' }, { title: '通过', name: 'pass' }, { title: '驳回', name: 'fail' },],
        name: 'providerVerify',
        component: () => import('@/views/Layout/provider/verify.vue')
      },
    ]
  },
  // 任务管理
  {
    path: '/task',
    redirect: '/task/cloud',
    meta: { title: '任务管理' },
    name: 'task',
    component: Layout,
    children: [
      {
        path: '/task/orders',
        meta: { title: '全部订单' },
        button: [{ title: '取消任务订单', name: 'cancel' }, { title: '导出', name: 'export' },],
        name: 'taskOrders',
        component: () => import('@/views/Layout/task/orders.vue')
      },
      {
        path: '/task/qrcode',
        meta: { title: '扫码直发' },
        name: 'taskQrcode',
        button: [{ title: '导出', name: 'export' }, { title: '指派', name: 'assign' }, { title: '查看订单', name: 'checkOrder' }, { title: '查看详情', name: 'detail' },],
        component: { render(c) { return c('router-view') } },
        redirect: 'task/qrcode',
        children: [
          {
            path: '/task/qrcode',
            component: () => import('@/views/Layout/task/qrcode.vue'),
          },
          {
            path: '/task/qrcode/detail',
            meta: { title: '任务详情' },
            name: 'taskDetail',
            component: () => import('@/views/Layout/task/detail.vue')
          }
        ]

      },

      {
        path: '/task/cloud',
        meta: { title: '云探任务' },
        name: 'taskCloud',
        button: [{ title: '导出', name: 'export' }, { title: '指派', name: 'assign' }, { title: '查看订单', name: 'checkOrder' }, { title: '查看详情', name: 'detail' },],
        // component: () => import('@/views/Layout/task/cloud.vue'),
        component: { render(c) { return c('router-view') } },
        redirect: '/task/cloud',
        children: [
          {
            path: '/task/cloud',
            component: () => import('@/views/Layout/task/cloud.vue'),
          },
          {
            path: '/task/cloud/detail',
            meta: { title: '任务详情' },
            name: 'taskDetail',
            component: () => import('@/views/Layout/task/detail.vue')
          }
        ]
      },
      {
        path: '/task/store',
        meta: { title: '实探任务' },
        name: 'taskStore',
        button: [{ title: '导出', name: 'export' }, { title: '指派', name: 'assign' }, { title: '查看订单', name: 'checkOrder' }, { title: '查看详情', name: 'detail' },],
        // component: () => import('@/views/Layout/task/store.vue')
        component: { render(c) { return c('router-view') } },
        redirect: '/task/store',
        children: [
          {
            path: '/task/store',
            component: () => import('@/views/Layout/task/store.vue')
          },
          {
            path: '/task/store/detail',
            meta: { title: '任务详情' },
            name: 'taskDetail',
            component: () => import('@/views/Layout/task/detail.vue')
          }
        ]
      },
      {
        path: '/task/offline',
        meta: { title: '实探线下' },
        name: 'taskStore',
        button: [{ title: '导出', name: 'export' }, { title: '指派', name: 'assign' }, { title: '查看订单', name: 'checkOrder' }, { title: '查看详情', name: 'detail' },],
        // component: () => import('@/views/Layout/task/store.vue')
        component: { render(c) { return c('router-view') } },
        redirect: '/task/store',
        children: [
          {
            path: '/task/offline',
            component: () => import('@/views/Layout/task/offline.vue')
          },
          {
            path: '/task/offline/detail',
            meta: { title: '任务详情' },
            name: 'taskDetail',
            component: () => import('@/views/Layout/task/detail.vue')
          }
        ]
      },
      // {
      //   path: '/task/detail',
      //   meta: { title: '任务详情' },
      //   name: 'taskDetail',
      //   component: () => import('@/views/Layout/task/detail.vue')
      // }, 
      {
        path: '/task/cancelOrder',
        meta: { title: '订单取消' },
        name: 'cancelOrder',
        component: () => import('@/views/Layout/task/cancelOrder.vue')
      },
    ]
  },
  //运营管理
  {
    path: '/operation',
    redirect: '/operation/qrCodeManagement',
    meta: { title: '运营管理' },
    name: 'operation',
    component: Layout,
    children: [
      {
        path: '/operation/qrCodeManagement',
        meta: { title: '二维码管理' },
        button: [{ title: '新增', name: 'sort' },],
        name: 'operation',
        component: () => import('@/views/Layout/operation/qrCodeManagement.vue')
      },
      {
        path: '/operation/top',
        meta: { title: '任务排序' },
        button: [{ title: '排序', name: 'sort' },],
        name: 'operationTop',
        component: () => import('@/views/Layout/operation/top.vue')
      },
      {
        path: '/operation/banner',
        meta: { title: 'banner配置' },
        button: [{ title: '新增', name: 'create' }, { title: '编辑', name: 'edit' }, { title: '删除', name: 'delete' }, { title: '启用', name: 'start' }, { title: '禁用', name: 'forbidden' },],
        name: 'operationBanner',
        component: () => import('@/views/Layout/operation/banner.vue')
      },
      {
        path: '/operation/socialCommunity',
        meta: { title: '社群配置' },
        // button: [{ title: '确定', name: 'confirm' },],
        name: 'socialCommunity',
        component: () => import('@/views/Layout/operation/socialCommunity.vue')
      },
      {
        path: '/operation/infoClassify',
        meta: { title: '资讯分类' },
        button: [{ title: '新增分类', name: 'create' }, { title: '编辑', name: 'edit' }, { title: '删除', name: 'delete' }, { title: '启用', name: 'start' }, { title: '禁用', name: 'forbidden' },],
        name: 'operationInfoclassify',
        component: () => import('@/views/Layout/operation/infoClassify.vue')
      },
      {
        path: '/operation/infoManage',
        meta: { title: '资讯管理' },
        button: [{ title: '新增资讯', name: 'create' }, { title: '编辑', name: 'edit' }, { title: '删除', name: 'delete' }, { title: '发送', name: 'send' }, { title: '查看', name: 'detail' },],
        name: 'operationInfomanage',
        component: () => import('@/views/Layout/operation/infoManage.vue')
      },
      {
        path: '/operation/createInfo',
        meta: { title: '新增资讯' },
        name: 'operationCreateinfo',
        component: () => import('@/views/Layout/operation/createInfo.vue')
      },
      {
        path: '/operation/newsDetail',
        meta: { title: '资讯详情' },
        name: 'operationNewsdetail',
        component: () => import('@/views/Layout/operation/newsDetail.vue')
      },
      {
        path: '/operation/inviteFriend',
        meta: { title: '邀请好友' },
        component: () => import('@/views/Layout/operation/inviteFriend.vue')
      },
      {
        path: '/operation/adPopup',
        meta: { title: '首页弹框' },
        name: 'operationAdpopup',
        button: [{ title: '新增', name: 'create' }, { title: '编辑', name: 'edit' }, { title: '删除', name: 'delete' }, { title: '启用', name: 'start' }, { title: '禁用', name: 'forbidden' },],
        component: () => import('@/views/Layout/operation/adPopup.vue')
      },
      {
        path: '/operation/adPopupup',
        meta: { title: '页面弹框' },
        name: 'operationAdpopupup',
        button: [{ title: '新增', name: 'create' }, { title: '编辑', name: 'edit' }, { title: '删除', name: 'delete' }, { title: '启用', name: 'start' }, { title: '禁用', name: 'forbidden' },],
        component: { render(c) { return c('router-view') } },
        redirect: '/operation/adPopupup',
        children: [
          {
            path: '/operation/adPopupup',
            name: 'operationAdpopupup',
            button: [{ title: '新增', name: 'create' }, { title: '编辑', name: 'edit' }, { title: '删除', name: 'delete' }, { title: '启用', name: 'start' }, { title: '禁用', name: 'forbidden' },],
            component: () => import('@/views/Layout/operation/adPopupup.vue')
          },
          {
            path: '/operation/adPopupup/popupData',
            meta: { title: '弹框数据' },
            name: 'operationadPopupuppopupData',
            button: [{ title: '新增', name: 'create' }, { title: '编辑', name: 'edit' }, { title: '删除', name: 'delete' }, { title: '启用', name: 'start' }, { title: '禁用', name: 'forbidden' },],
            component: () => import('@/views/Layout/operation/popupData.vue')
          },]
      },
      {
        path: '/operation/popupRoute',
        meta: { title: '弹框路由' },
        name: 'operationAdpopupup',
        button: [{ title: '新增', name: 'create' }, { title: '编辑', name: 'edit' }, { title: '删除', name: 'delete' }, { title: '启用', name: 'start' }, { title: '禁用', name: 'forbidden' },],
        component: () => import('@/views/Layout/operation/popupRoute.vue')
      },
      {
        path: '/operation/exportRecord',
        meta: { title: '导出记录' },
        name: 'exportRecord',
        // button: [{ title: '修改', name: 'modify' },],
        component: () => import('@/views/Layout/operation/exportRecord.vue')
      },
      {
        path: '/operation/hideTask',
        meta: { title: '隐藏任务' },
        name: 'hideTask',
        // button: [{ title: '修改', name: 'modify' },],
        component: () => import('@/views/Layout/operation/hideTask.vue')
      },
      {
        path: '/operation/noticeManage',
        meta: { title: '公告管理' },
        button: [{ title: '新增', name: 'create' }, { title: '编辑', name: 'edit' }, { title: '删除', name: 'delete' }, { title: '启用', name: 'start' }, { title: '禁用', name: 'forbidden' },],
        name: 'noticeManage',
        component: { render(c) { return c('router-view') } },
        // component: () => import('@/views/Layout/operation/noticeManage.vue'),
        redirect: '/operation/notice',
        children: [
          {
            path: '/operation/notice',
            component: () => import('@/views/Layout/operation/noticeManage.vue'),

          },
          {
            path: '/operation/noticeManage/addNotice',
            meta: { title: '新增公告' },
            name: 'addNotice',
            //  button: [{ title: '新增', name: 'create' }, { title: '编辑', name: 'edit' }, { title: '删除', name: 'delete' }, { title: '启用', name: 'start' }, { title: '禁用', name: 'forbidden' },],
            component: () => import('@/views/Layout/operation/addNotice.vue'),
          }
        ]
      },
    ]
  },
  //运营管理
  {
    path: '/operation',
    redirect: '/operation/top',
    meta: { title: '运营管理' },
    name: 'operation',
    component: Layout,
    children: [
      {
        path: '/operation/top',
        meta: { title: '任务排序' },
        button: [{ title: '排序', name: 'sort' },],
        name: 'operationTop',
        component: () => import('@/views/Layout/operation/top.vue')
      },
      {
        path: '/operation/banner',
        meta: { title: 'banner配置' },
        button: [{ title: '新增', name: 'create' }, { title: '编辑', name: 'edit' }, { title: '删除', name: 'delete' }, { title: '启用', name: 'start' }, { title: '禁用', name: 'forbidden' },],
        name: 'operationBanner',
        component: () => import('@/views/Layout/operation/banner.vue')
      },
      {
        path: '/operation/socialCommunity',
        meta: { title: '社群配置' },
        // button: [{ title: '确定', name: 'confirm' },],
        name: 'socialCommunity',
        component: () => import('@/views/Layout/operation/socialCommunity.vue')
      },
      {
        path: '/operation/infoClassify',
        meta: { title: '资讯分类' },
        button: [{ title: '新增分类', name: 'create' }, { title: '编辑', name: 'edit' }, { title: '删除', name: 'delete' }, { title: '启用', name: 'start' }, { title: '禁用', name: 'forbidden' },],
        name: 'operationInfoclassify',
        component: () => import('@/views/Layout/operation/infoClassify.vue')
      },
      {
        path: '/operation/infoManage',
        meta: { title: '资讯管理' },
        button: [{ title: '新增资讯', name: 'create' }, { title: '编辑', name: 'edit' }, { title: '删除', name: 'delete' }, { title: '发送', name: 'send' }, { title: '查看', name: 'detail' },],
        name: 'operationInfomanage',
        component: () => import('@/views/Layout/operation/infoManage.vue')
      },
      {
        path: '/operation/createInfo',
        meta: { title: '新增资讯' },
        name: 'operationCreateinfo',
        component: () => import('@/views/Layout/operation/createInfo.vue')
      },
      {
        path: '/operation/newsDetail',
        meta: { title: '资讯详情' },
        name: 'operationNewsdetail',
        component: () => import('@/views/Layout/operation/newsDetail.vue')
      },
      {
        path: '/operation/adPopup',
        meta: { title: '首页弹框' },
        name: 'operationAdpopup',
        button: [{ title: '新增', name: 'create' }, { title: '编辑', name: 'edit' }, { title: '删除', name: 'delete' }, { title: '启用', name: 'start' }, { title: '禁用', name: 'forbidden' },],
        component: () => import('@/views/Layout/operation/adPopup.vue')
      },
      {
        path: '/operation/exportRecord',
        meta: { title: '导出记录' },
        name: 'exportRecord',
        // button: [{ title: '修改', name: 'modify' },],
        component: () => import('@/views/Layout/operation/exportRecord.vue')
      },
      {
        path: '/operation/hideTask',
        meta: { title: '隐藏任务' },
        name: 'hideTask',
        // button: [{ title: '修改', name: 'modify' },],
        component: () => import('@/views/Layout/operation/hideTask.vue')
      },
      {
        path: '/operation/noticeManage',
        meta: { title: '公告管理' },
        button: [{ title: '新增', name: 'create' }, { title: '编辑', name: 'edit' }, { title: '删除', name: 'delete' }, { title: '启用', name: 'start' }, { title: '禁用', name: 'forbidden' },],
        name: 'noticeManage',
        component: { render(c) { return c('router-view') } },
        // component: () => import('@/views/Layout/operation/noticeManage.vue'),
        redirect: '/operation/notice',
        children: [
          {
            path: '/operation/notice',
            component: () => import('@/views/Layout/operation/noticeManage.vue'),

          },
          {
            path: '/operation/noticeManage/addNotice',
            meta: { title: '新增公告' },
            name: 'addNotice',
            //  button: [{ title: '新增', name: 'create' }, { title: '编辑', name: 'edit' }, { title: '删除', name: 'delete' }, { title: '启用', name: 'start' }, { title: '禁用', name: 'forbidden' },],
            component: () => import('@/views/Layout/operation/addNotice.vue'),
          }
        ]
      },
    ]
  },
  //知识库
  {
    path: '/knowledgebase',
    redirect: '/knowledgebase/list',
    meta: { title: '知识库' },
    name: 'knowledgebase',
    component: Layout,
    children: [
      {
        path: '/knowledgebase/list',
        meta: { title: '知识库' },
        button: [{ title: '排序', name: 'sort' },],
        name: 'knowledgebase',
        component: () => import('@/views/Layout/knowledgebase/list.vue')
      },
      {
        path: '/knowledgebase/userList',
        meta: { title: '知识库搜索' },
        button: [{ title: '排序', name: 'sort' },],
        name: 'knowledgebase',
        component: () => import('@/views/Layout/knowledgebase/userList.vue')
      },
      {
        path: '/knowledgebase/aigc',
        meta: { title: 'AIGC模板' },
        button: [{ title: '排序', name: 'sort' },],
        name: 'knowledgebase',
        component: () => import('@/views/Layout/knowledgebase/AIGC.vue')
      },
      {
        path: '/knowledgebase/add',
        meta: { title: '知识库新增' },
        button: [{ title: '新增', name: 'create' }, { title: '编辑', name: 'edit' }, { title: '删除', name: 'delete' }, { title: '启用', name: 'start' }, { title: '禁用', name: 'forbidden' },],
        name: 'knowledgebaseAdd',
        component: () => import('@/views/Layout/knowledgebase/add.vue')
      },

    ]
  },
  //剪刀手aigc文案
  {
    path: '/scissor',
    redirect: '/knowledgebase/copywriting-list',
    meta: { title: '剪刀手' },
    name: 'scissor',
    component: Layout,
    children: [

      {
        path: '/scissor/copywriting-list',
        meta: { title: '文案列表' },
        button: [{ title: '排序', name: 'sort' },],
        name: 'scissor',
        component: () => import('@/views/Layout/scissor/copywriting-list.vue')
      },
      {
        path: '/scissor/title-list',
        meta: { title: '标题列表' },
        button: [{ title: '排序', name: 'sort' },],
        name: 'scissor',
        component: () => import('@/views/Layout/scissor/title-list.vue')
      },
      {
        path: '/scissor/abstract',
        meta: { title: '摘要列表' },
        button: [{ title: '排序', name: 'sort' },],
        name: 'scissor',
        component: () => import('@/views/Layout/scissor/abstract.vue')
      },
      {
        path: '/scissor/rechargeRecord',
        meta: { title: '充值记录' },
        name: 'scissor',
        button: [{ title: '通过', name: 'pass' }, { title: '驳回', name: 'fail' },],
        component: () => import('@/views/Layout/scissor/rechargeRecord.vue')
      },
      {
        path: '/scissor/numbeRecords',
        meta: { title: '充值记录' },
        name: 'scissor',
        button: [{ title: '通过', name: 'pass' }, { title: '驳回', name: 'fail' },],
        component: () => import('@/views/Layout/scissor/numbeRecords.vue')
      },
      {
        path: '/scissor/share',
        meta: { title: '充值配置' },
        name: 'scissor',
        button: [{ title: '修改', name: 'modify' },],
        component: () => import('@/views/Layout/scissor/share.vue')
      },
      {
        path: '/scissor/rechargePackage',
        meta: { title: '充值套餐' },
        name: 'scissor',
        button: [{ title: '修改', name: 'modify' },],
        component: () => import('@/views/Layout/scissor/rechargePackage.vue')
      },
      {
        path: '/scissor/taskList',
        meta: { title: '任务列表' },
        name: 'scissor',
        component: { render(c) { return c('router-view') } },
        redirect: '/scissor/taskList',
        children: [
          {
            path: '/scissor/taskList',
            component: () => import('@/views/Layout/scissor/taskList'),
          },
          {
            path: '/scissor/taskList/taskListDetail',
            meta: { title: '任务详情' },
            name: 'taskDetail',
            component: () => import('@/views/Layout/scissor/taskListDetail')
          }
        ]
      },
    ]
  },
  //配置管理
  {
    path: '/config',
    redirect: '/config/distributor',
    meta: { title: '配置管理' },
    name: 'config',
    component: Layout,
    children: [
      {
        path: '/config/distributor',
        meta: { title: '分销商配置' },
        button: [{ title: '确定', name: 'confirm' },],
        name: 'configDistributor',
        component: () => import('@/views/Layout/config/distributor.vue')
      },

      {
        path: '/config/money',
        meta: { title: '任务金配置' },
        button: [{ title: '确定', name: 'confirm' },],
        name: 'configMoney',
        component: () => import('@/views/Layout/config/money.vue')
      },
      {
        path: '/config/autoCancel',
        meta: { title: '任务配置' },
        name: 'autoCancel',
        button: [{ title: '确定', name: 'confirm' },],
        component: () => import('@/views/Layout/config/autoCancel.vue')
      },
      {
        path: '/config/customer',
        meta: { title: '客服配置' },
        name: 'customer',
        button: [{ title: '修改', name: 'modify' },],
        component: () => import('@/views/Layout/config/customer.vue')
      },
      {
        path: '/config/limitWithdrawal',
        meta: { title: '提现配置' },
        name: 'limitWithdrawal',
        button: [{ title: '修改', name: 'modify' },],
        component: () => import('@/views/Layout/config/limitWithdrawal.vue')
      },
      {
        path: '/config/replayRmark',
        meta: { title: '达人审核配置' },
        name: 'replayRmark',
        button: [{ title: '修改', name: 'modify' },],
        component: () => import('@/views/Layout/config/replayRmark.vue')
      },
      {
        path: '/config/link',
        meta: { title: '链接设置' },
        name: 'link',
        button: [{ title: '修改', name: 'modify' },],
        component: () => import('@/views/Layout/config/link.vue')
      },


    ]
  },
  //资金管理
  {
    path: '/asset',
    redirect: '/asset/provider',
    meta: { title: '资金管理' },
    name: 'asset',
    component: Layout,
    children: [
      {
        path: '/asset/provider',
        meta: { title: '服务商明细' },
        name: 'assetProvider',
        component: () => import('@/views/Layout/asset/provider.vue')
      },
      {
        path: '/asset/master',
        meta: { title: '达人明细' },
        name: 'assetMaster',
        component: () => import('@/views/Layout/asset/master.vue')
      },
      {
        path: '/asset/withdrawalVerify',
        meta: { title: '达人提现审核' },
        name: 'assetWithdrawal',
        button: [{ title: '通过', name: 'pass' }, { title: '驳回', name: 'fail' },],
        component: () => import('@/views/Layout/asset/withdrawalVerify.vue')
      },
      {
        path: '/asset/serviceWithdrawal',
        meta: { title: '服务商提现审核' },
        name: 'serviceWithdrawal',
        button: [{ title: '通过', name: 'pass' }, { title: '驳回', name: 'fail' },],
        component: () => import('@/views/Layout/asset/serviceWithdrawal.vue')
      },
      {
        path: '/asset/commission',
        meta: { title: '代理收益明细' },
        name: 'commission',
        component: () => import('@/views/Layout/asset/commission.vue')
      }
    ]
  },
  //报表管理
  {
    path: '/report',
    redirect: '/report/day',
    meta: { title: '报表管理' },
    name: 'report',
    component: Layout,
    children: [
      {
        path: '/report/day',
        meta: { title: '日报表' },


        component: { render(c) { return c('router-view') } },
        children: [
          {
            path: '/report/day',

            name: 'reportDay',
            button: [{ title: '导出', name: 'export' },],
            component: () => import('@/views/Layout/report/day.vue'),
          },
          {
            path: '/report/daydetail',
            meta: { title: '日报表详情' },
            name: 'reportDayDetail',
            button: [{ title: '导出', name: 'export' },],
            component: () => import('@/views/Layout/report/daydetail.vue')
          },
        ]
      },

      {
        path: '/report/task',
        component: { render(c) { return c('router-view') } },
        meta: { title: '任务报表' },
        children: [
          {
            path: '/report/task',

            name: 'reportTask',
            button: [{ title: '导出', name: 'export' },],
            component: () => import('@/views/Layout/report/task.vue'),
          },
          {
            path: '/report/taskdetail',
            meta: { title: '任务报表详情' },
            name: 'reportTaskDetail',
            button: [{ title: '导出', name: 'export' },],
            component: () => import('@/views/Layout/report/taskdetail.vue')
          },
        ]

      },

    ]
  },
  //系统管理
  {
    path: '/system',
    redirect: '/system/user',
    meta: { title: '系统管理' },
    name: 'system',
    component: Layout,
    children: [
      {
        path: '/system/user',
        meta: { title: '用户管理' },
        button: [{ title: '新增用户', name: 'create' }, { title: '重置密码', name: 'reset' }, { title: '编辑', name: 'edit' }, { title: '删除', name: 'delete' },],
        name: 'systemUser',
        component: () => import('@/views/Layout/system/user.vue')
      },
      {
        path: '/system/role',
        meta: { title: '角色管理' },
        button: [{ title: '新增角色', name: 'create' }, { title: '编辑', name: 'edit' }, { title: '删除', name: 'delete' }, { title: '角色权限', name: 'authority' },],
        name: 'systemRole',
        component: () => import('@/views/Layout/system/role.vue')
      },
      {
        path: '/system/loginLog',
        meta: { title: '登录日志' },
        name: 'systemLoginlog',
        component: () => import('@/views/Layout/system/loginLog.vue')
      },
      {
        path: '/system/operateLog',
        name: 'systemOperatelog',
        meta: { title: '操作日志' },
        component: () => import('@/views/Layout/system/operateLog.vue')
      },
    ]
  },
  // 
  {
    path: '/userCenter',
    redirect: '/userCenter/userInfo',
    meta: { title: '用户中心' },
    name: 'userCenter',
    component: Layout,
    children: [
      {
        path: '/userCenter/userInfo',
        meta: { title: '用户信息' },
        component: { render(c) { return c('router-view') } },
        redirect: '/userCenter/userInfo',
        children: [
          {
            path: '/userCenter/userInfo',
            component: () => import('@/views/Layout/userCenter/userInfo.vue'),
          },
          {
            path: '/userCenter/userInfo/userInfoDetail',
            meta: { title: "下级详情" },
            component: () => import('@/views/Layout/userCenter/userInfoDetail.vue'),
          }
        ]
      },
      {
        path: '/userCenter/addPiece',
        meta: { title: '添加条数' },
        name: 'userCenter',
        component: () => import('@/views/Layout/userCenter/addPiece.vue')

      },
      {
        path: '/userCenter/addPieceRecord',
        meta: { title: '操作记录' },
        name: 'userCenter',
        component: () => import('@/views/Layout/userCenter/addPieceRecord.vue')

      },
      {
        path: '/userCenter/record',
        meta: { title: '操作记录' },
        name: 'userCenter',
        component: () => import('@/views/Layout/userCenter/record.vue')
      },
      {
        path: '/userCenter/changeMoney',
        meta: { title: '变更余额' },
        name: 'userCenter',
        component: () => import('@/views/Layout/userCenter/changeMoney.vue')
      },
      {
        path: '/userCenter/shareLog',
        meta: { title: '分享记录' },
        name: 'userCenter',
        component: () => import('@/views/Layout/userCenter/shareLog.vue')
      }
    ],
    // // children: [
    //   {
    //     path: '/userCenter/userList',
    //     meta: { title: '用户列表' },
    //     name: 'userCenter',
    //     component: { render(c) { return c('router-view') } },
    //     redirect: '/userCenter/userList',
    //     children: [
    //       {
    //         path: '/userCenter/userList',
    //         component: () => import('@/views/Layout/userCenter/userList.vue'),
    //         // meta: {
    //         //   keepAlive: true
    //         // }
    //       },
    //       {
    //         path: '/userCenter/loginLog',
    //         component: () => import('@/views/Layout/userCenter/loginLog.vue'),
    //         meta: { title: '登录日志' },
    //         name: "userCenter",
    //       },
    //       {
    //         path: '/userCenter/shareLog',
    //         meta: { title: '分享记录' },
    //         name: "userCenter",
    //         component: () => import('@/views/Layout/userCenter/shareLog.vue')
    //       }
    //     ]
    //   },
    //   {
    //     path: '/userCenter/walletList',
    //     meta: { title: '钱包列表' },
    //     name: 'userList',
    //     component: () => import('@/views/Layout/userCenter/walletList.vue')
    //   },
    // ]

  },
  // 容错页
  {
    path: '/*',
    component: () => import('@/Error.vue')
  }

]

const router = new VueRouter({
  mode: 'history',//history//hash
  base: process.env.BASE_URL,
  routes
})


export default router

