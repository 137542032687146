import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "@/static/style/common.css"
import "@wangeditor/editor/dist/css/style.css"
import moment from 'moment';
import * as echarts from "echarts";
import DisableAudio from '@/utils/pause';
import * as filter from '@/utils/function.js'
Object.assign(Vue.prototype, filter);
Vue.use(DisableAudio);

// 方法1：注册播放音频事件到Vue实例上
Vue.prototype.playAudio = () => {
  let buttonAudio = document.getElementById('eventAudio');

  buttonAudio.setAttribute('src', 'https://store-tiktok.oss-cn-beijing.aliyuncs.com/admin/MP3/aaaa.mp3');

  buttonAudio.play();
}
Vue.prototype.$echarts = echarts;
Vue.prototype.$moment = moment;
Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.prototype.$bus = new Vue()
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
