// 引入axios
import axios from 'axios'
// 引入elementui的Message
import { Message } from 'element-ui'
import router from '@/router'
import _ from 'lodash'
// 配置服务器地址
// axios.defaults.baseURL = process.env.VUE_APP_API_URL

const service = axios.create({
    baseURL: process.env.VUE_APP_API_URL,

    timeout: 200000 // 请求超时时间
})

// 创建一个节流函数，限制请求频率为每秒一次
// const throttledRequest = _.throttle((config) => {
//     return config
// }, 1000);

// 请求拦截器
service.interceptors.request.use(config => {


    // 设置token
    config.headers['business'] = "talent"
    config.headers['version'] = '1.0.5'
    const token = localStorage.getItem('tk')
    if (token) {
        config.headers.Authorization = 'Bearer ' + token;

    }
    return config
}, err => {
    return Promise.reject(err)
})

// 响应拦截器
service.interceptors.response.use(res => {
    if (res.data.code == 202) {
        Message.error(res.data.msg)
    }
    if (res.data.code == 422) {
        Message.error(res.data.msg)
    }
    if (res.data.code == 403) {
        Message.error(res.data.msg)
    }
    if (res.data.code == 401) {
        Message.error(res.data.msg);
        router.push('/')
    }
    if (res.data.code == 500) {
        Message.error('哎呀，服务器出了点小问题~');
    }
    return res
}, err => {
    // console.log(123, err);
    if (err.code == 'ECONNABORTED') {
        Message.error('请求超时')
    }
    if (err.code == 'ERR_NETWORK') {
        Message.error(err.Message)
    }
    return Promise.reject(err)
})

export default service